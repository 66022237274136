import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {ExportDTO} from '../../model/dto/export.dto';
import {ExportTypeEnum} from '../../model/enums/export-type.enum';
import {ReportingDto} from '../../model/dto/reporting.dto';
import {OccurrencePdfDTO} from '../../model/dto/occurrence-pdf.dto';
import {SimpleInterviewDTO} from '../../model/dto/simple-interview.dto';
import {SignatureVisitModel} from '../../model/event/signature-visit.model';
import {getMobileToken} from 'src/app/utils/utils.static';
import {AbstractWebservice} from "./abstract.webservice";
import {UrlBuilder} from "../../utils/helpers/url-builder.helper";

@Injectable({
  providedIn: 'root'
})
export class ExportWebservice extends AbstractWebservice {
   entity = `exports`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  // Recupere l'export sur l'api
  getExport(exp: ExportDTO) {
    return this.post(`${this.root}`, exp, {responseType: 'blob'});
  }

  postExportReporting(rep: ReportingDto) {
    return this.post(`${this.root}/reporting`, rep, {responseType: 'blob'});
  }

  postExportPresence(occurrencePdf: OccurrencePdfDTO) {
    return this.post(`${this.root}/pdf`, occurrencePdf, {responseType: 'blob'});
  }

  postExportInterview(simpleInterviewDtoList: SimpleInterviewDTO[]) {
    return this.post(`${this.root}/pdfInterview`, simpleInterviewDtoList, {
      responseType: 'blob'
    });
  }

  // Renvoie l'enumeration a l'index demande
  getExportType(index) {
    switch (index) {
      case 0:
        return ExportTypeEnum.EXPORT_NB_JEUNE_INSCRIT;
      case 1:
        return ExportTypeEnum.EXPORT_GLOBAL_JEUNE;
      case 2:
        return ExportTypeEnum.EXPORT_NOMBRE_MOYEN_JEUNE;
      case 3:
        return ExportTypeEnum.EXPORT_GLOBAL_INSCRIPTION;
      case 4:
        return ExportTypeEnum.EXPORT_NOMBRE_JEUNE_NON_PRESENT;
      case 5:
        return ExportTypeEnum.EXPORT_NOMBRE_INSCRIPTION_SUPPRIME;
      default:
        return null;
    }
  }

  getMailRi() {
    return this.get(`${environment.envApiUrl}/Test/email`);
  }

  printPDFVisite(visitId: number) {
    const url = new UrlBuilder(this.root)
      .addPathParams('visite-pdf', visitId)
      .build();
    return this.get(url, {responseType: 'blob'});
  }

  signPDFVisite(visitId: number, signatures: SignatureVisitModel) {
    const url = new UrlBuilder(this.root)
      .addPathParams('sign-visite-pdf', visitId)
      .build();
    return this.post(url, signatures);
  }
}
